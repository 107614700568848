import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import Form from './Form'

function GroupPage(props){
  const [sectionStyle, setStyle] = useState({})
  const [ group, setGroup] = useState({})

  // contact form params
  const form_config = {
      api: 'https://www.mut-gruppen.de/api/contact/index.php',
      title: 'Kontakt zu ' + group.name,
      successMessage: 'Danke! Deine Nachricht wurde versandt!',
      errorMessage: 'Bitte fülle das gesamte Formular aus!',

      fieldsConfig:  [
       { id: 1, label: 'Name', fieldName: 'name', type: 'text',placeholder:'Name', isRequired: true },
       { id: 2, label: 'Email', fieldName: 'email', type: 'email', placeholder: ' Deine Email Adresse', isRequired: true },
       { id: 3, label: 'Nachricht', fieldName: 'msg', type: 'textarea',placeholder:'Schreib\' uns!', isRequired: true },
       { id: 5, name: 'receiver', value: group.mail, type: 'hidden' },
       { id: 4, name: 'gandalf', value: '3hhfh23', type: 'hidden' }
      ]
    }

  // no start page at reload 
  useEffect( ()=>{props.reset(false) },[props] )

  useEffect( ()=>{
    props.groups.forEach( el => {

      let url = (el.name).toLowerCase()
      url = url.replace(/ /g, "-")

      if ( url === props.match.params.name ) {setGroup(el) }

    })   
  },[props])

  useEffect( ()=>{
    (typeof group.bgimg !== 'undefined') 
      ? setStyle({backgroundImage: `url(${group.bgimg})` })
      : setStyle({backgroundImage: `url(/blur-greenblue.jpg)`})
  },[group] )

  const backToStart = ()=>{props.reset(true) }

  return (
    <> 
      <header className="header_group_page">
        <img src="logo.gif" alt="Logo"/>
        <Link  to="/" onClick={()=>(backToStart())}>Zu anderen Gruppen <span className="arrow-right"></span></Link>
      </header>
      <section className="group_page topic">
        <p>Behandelte Themen: {group.topic} </p>
        <p>aktive / noch mögliche Teilnehmende: {group.member_count} / {group.free_slots} </p>
      </section>
      <div className="group_page logo_container" style={ sectionStyle }>
      { ( group.show_logo && group.logo.length > 0 ) &&
        <img  className="logo" src={group.logo} alt={group.name}></img>
      }
      </div>
      <section className="group_page about">
        <h1>Über Uns</h1>
        <div dangerouslySetInnerHTML={{ __html: group.about }} />
      </section>
      <section className="group_page meet">
        <h1>Treffen</h1>
        <div dangerouslySetInnerHTML={{ __html: group.meetings }} />
      </section>
      <section className="group_page contact">
        <h1>Kontakt</h1>
        <div>
          <Form config={form_config}/>
          <div className="contact-form">
            <p>Email: <a href={`mailto:${group.mail}`}>{group.mail}</a></p>
            <p>Telefon: <a href={`tel:${group.tel}`}>{group.tel}</a></p>
          </div>
        </div>
      </section>
    </>
  );
}

export default GroupPage;
