import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import {BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import GroupPage from './components/GroupPage'

function _prepareURL(url){
  url = url.toLowerCase()
  url = url.replace(/ /g, "-")

  return url
}


function App(props) {

  const [groups, setGroups] = useState([{name:''}]);
  const [bottomContent, setBottomContent] = useState('');
  const [topContent, setTopContent] = useState('');
  const [loading, setLoading] = useState(true)
  const [start, setStart] = useState(true)

  // reset Start Page on Browser Back
  useEffect(()=>{
    window.onpopstate = e => {
      setStart(true)
    }
  })

  // get Data
  useEffect(() => {
    axios
      .get('groups.json')
      .then(({ data })=> {
        let d = JSON.stringify(data)
        setTopContent(JSON.parse(d).top)
        setBottomContent(JSON.parse(d).bottom)
        setGroups(JSON.parse(d).groups)
        setLoading(false)

      })
      .catch((err)=> {
        console.log(err)
      })
  }, []);


    
 return (
    <div className="App">
      <Router>
        { start &&
          <>
          <header>
            <img src="logo.gif" alt="Logo"/>
            <h1>Projekte für mehr Mut und Wissen im Umgang mit psychischen Erkrankungen.</h1>
            <div className="main-top" dangerouslySetInnerHTML={{ __html: topContent }} />
            <h2>Unsere Gruppen</h2>
          </header>
           <div className="gruppen_liste">
              {loading === true ? (
                  <div className="gruppen_logo">Loading...</div>
                  ) : (
                    groups.map( group => (<Link to={`/${  _prepareURL(group.name) }`}  onClick={()=>{setStart(false)}} key={group.id} > <img className="gruppen_logo"  src={group.logo} alt={`Logo ${group.name}`}></img></Link> ) )
              )}
              <a href="https://www.mut-foerdern.de/kontakt"><div className="gruppen_logo"><span className="gruppen_logo_inner_text">Deine Gruppe hier?</span></div></a>
          </div>
          <div className="main-bottom" dangerouslySetInnerHTML={{ __html: bottomContent }} />
          </>
        }

        <Switch>
          <Route path="/:name" render={(props) => <GroupPage {...props} groups={groups} reset={setStart} />} />
        </Switch>
      </Router>
      <footer>
        <nav>
            <a href="https://www.mut-foerdern.de">Mut fördern e.V.</a>
            <a href="https://www.mut-tour.de/">Andere MUT-Projekte</a>
            <a href="https://www.mut-foerdern.de/datenschutz">Datenschutz</a>
            <a href="https://www.mut-foerdern.de/impressum">Impressum</a>
            <a href="https://www.mut-foerdern.de/kontakt">Kontakt</a>
        </nav>
      </footer>



    </div>
  );
}
export default App;
